// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  // app theme color
  --theme-color: #131313;
  --ion-color-primary: linear-gradient(180deg, #920515 0%, #D71630 100%);
  --label-color : #fff;
  --ion-background-color: transparant;
  --card-radius: 14px;
  --btn-radius: 14px;
  --btn-height: 75px;
  --l-Gradient: linear-gradient(135deg, #1E2023 0%, #222427 100%);
  --ion-text-color : #fff;
  --text-color1: #fff;
  --text-color2: #423939;
  --bg: #1D1C1C;
  --progress: #14f496;
  --progress1: rgba(20, 244, 150, 0.35);
  --col-meg: linear-gradient(180deg, #CD8062 0%, #A46145 100%);
  --ion-text-color:#CFD0D0;
  --btn-shadow : 1px 1px 2px rgba(244, 19, 47, 0.3), -1px -1px 2px rgba(118, 9, 23, 0.5), inset -6px 6px 12px rgba(118, 9, 23, 0.2), inset 6px -6px 12px rgba(118, 9, 23, 0.2), inset -6px -6px 12px rgba(244, 19, 47, 0.9), inset 6px 6px 15px rgba(118, 9, 23, 0.9);
  --inner-shadow: 1px 1px 2px rgba(53, 58, 61, 0.3), -1px -1px 2px rgba(13, 14, 15, 0.5), inset -1px 1px 2px rgba(13, 14, 15, 0.2), inset 1px -1px 2px rgba(13, 14, 15, 0.2), inset -1px -1px 2px rgba(53, 58, 61, 0.9), inset 1px 1px 3px rgba(13, 14, 15, 0.9);
  --outer-shadow: -1px -1px 2px rgba(11, 12, 13, 0.2), 1px 1px 2px rgba(11, 12, 13, 0.2), -1px 1px 2px rgba(45, 50, 53, 0.9), 1px -1px 3px rgba(11, 12, 13, 0.9), inset 1px -1px 2px rgba(45, 50, 53, 0.3), inset -1px 1px 2px rgba(11, 12, 13, 0.5);
  --personal-shadow :  6px 6px 12px rgba(6, 0, 0, 0.32), -6px -6px 10px rgba(255, 255, 255, 0.04);
  --outer-common: -2px 2px 4px rgba(20, 21, 23, 0.2), 2px -2px 4px rgba(20, 21, 23, 0.2), -2px -2px 4px rgba(44, 47, 51, 0.9), 2px 2px 5px rgba(20, 21, 23, 0.9), inset 1px 1px 2px rgba(44, 47, 51, 0.3), inset -1px -1px 2px rgba(20, 21, 23, 0.5);
  
  /** primary **/
  --ion-color-primary: #B50E23;
  --ion-color-primary-rgb: 56, 128, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #B50E23;
  --ion-color-primary-tint: #B50E23;

  /** secondary **/
  --ion-color-secondary: #3dc2ff;
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #36abe0;
  --ion-color-secondary-tint: #50c8ff;

  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;
}

:root {
  --ion-font-family: 'Rajdhani';
}
